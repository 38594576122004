
import path from 'path'
import { isExternal } from '@/utils/validate'
// import Item from './Item.vue'
import AppLink from './Link.vue'
import { defineComponent } from 'vue'

interface Data {
  onlyOneChild: any
}

export default defineComponent({
  name: 'SidebarItem',
  components: { /* Item, */ AppLink },
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    basePath: {
      type: String,
      default: ''
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  data(): Data {
    
    return {
      onlyOneChild: null
    }
  },
  methods: {
    refreshHandle(item: any){
      
      if(item.meta && item.meta.path && window.location.hash.includes(item.meta.path)){
        window.location.replace(window.location.origin + '/#' + item.meta.path)
        window.location.reload();
      }
      
    },
    hasOneShowingChild(children = [], parent: any) {
      const showingChildren = children.filter((item: any) => {
        if (item.meta && item.meta.hidden === true) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })
      
      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ... parent, path: '', noShowingChildren: true }
        return true
      }

      // 如果不止一个子页面
      return false
    },
    resolvePath(routePath: any) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    }
  },
  computed: {
    singleItem() {
      return this.hasOneShowingChild(this.item.children, this.item)
    }
  }
})
