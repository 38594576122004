
import SidebarItem from './SidebarItem.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { defineComponent, computed, ref } from 'vue'
import { RouteRecordRaw } from 'vue-router'
export default defineComponent({
    components: { SidebarItem },
    props: {
        closeSidebar: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const store = useStore()
        const route = useRoute()

        let defMenuActive = ref<string>((route.meta && route.meta.path)as string || route.path)

        const fitterHiddenRoute = (routers: Array<RouteRecordRaw>) => {
            return routers.filter((_rout: RouteRecordRaw) => {
                if(!_rout.children) return _rout.meta && !_rout.meta.hidden;
                // 没有子菜单就不用展示
                _rout.children = fitterHiddenRoute(_rout.children)
                if(_rout.children.length > 0) return true;
                return false
            })
        }

        let accessRt = fitterHiddenRoute(store.state.permissionMd.sidebarRouters)

        const openArr = computed(() => {
            return accessRt.map(((_router: any) => _router.path))
        })

        return {
            accessRt,
            defMenuActive,
            openArr,
        }
    }

})

