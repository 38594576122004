import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar_item = _resolveComponent("sidebar-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createBlock(_component_el_scrollbar, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_menu, {
        "default-active": _ctx.defMenuActive,
        class: "sidebar",
        "background-color": "#545c64",
        "text-color": "#fff",
        "active-text-color": "#ffd04b",
        collapse: _ctx.closeSidebar
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accessRt, (route) => {
            return (_openBlock(), _createBlock(_component_sidebar_item, {
              key: route.path,
              item: route,
              "base-path": route.path
            }, null, 8, ["item", "base-path"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["default-active", "collapse"])
    ]),
    _: 1
  }))
}