
import { defineComponent } from 'vue'
import { isExternal } from '@/utils/validate'

export default defineComponent({
  props: {
    to: {
      type: String,
      required: true
    }
  },
  computed: {
    isOutExternal(): any {
      return isExternal(this.to)
    },
    type(): any {
      if (this.isOutExternal) {
        return 'a'
      }
      return 'router-link'
    }
  },
  methods: {
    linkProps(to: any) {
      if (this.isOutExternal) {
        return {
          href: to,
          target: '_blank',
          rel: 'noopener'
        }
      }
      return {
        to: to
      }
    }
  }
})
